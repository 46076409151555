'use client'
import { dateFirstAvailable } from '@lib/utilities/date-utilities'
import { useState, useEffect, type ComponentPropsWithRef } from 'react'
import clsx from 'clsx'
import { isValid } from 'date-fns'
import { Icon, Skeleton, Typography } from '@shc/ui'
import type { TypeProviderPublic } from '@lib/provider-types'
import { getFirstAvailableAppointments } from '@lib/services/actions/first-available-service'

export interface FirstAvailableProps extends ComponentPropsWithRef<'div'> {
  align?: 'left' | 'center'
  heading?: boolean
  provider: TypeProviderPublic
}

export const FirstAvailable = ({
  align = 'left',
  heading = false,
  provider,
}: FirstAvailableProps) => {
  const [dateVirtualDisplay, setDateVirtualDisplay] = useState<string | null>(null)
  const [dateInPersonDisplay, setDateInPersonDisplay] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        //get data
        const [availableAppointmentsVirtual, availableAppointmentsInPerson] = await Promise.all([
          getFirstAvailableAppointments(provider.id, 'virtual'),
          getFirstAvailableAppointments(provider.id, 'in-person'),
        ])

        //get data
        const firstAvailableAppointmentVirtual =
          availableAppointmentsVirtual?.length > 0 ? availableAppointmentsVirtual[0] : ''
        const firstAvailableAppointmentInPerson =
          availableAppointmentsInPerson?.length > 0 ? availableAppointmentsInPerson[0] : ''

        //format data
        if (
          firstAvailableAppointmentVirtual?.date &&
          firstAvailableAppointmentVirtual?.date.length > 0
        ) {
          const virtualApptDatetime = firstAvailableAppointmentVirtual?.date

          if (isValid(new Date(virtualApptDatetime))) {
            const virtualApptDate = dateFirstAvailable(virtualApptDatetime)
            setDateVirtualDisplay(`${virtualApptDate} (virtual)`)
          } else {
            console.error('Invalid date - virtual appointment')
            setDateVirtualDisplay('')
          }
        } else {
          setDateVirtualDisplay('')
        }

        if (
          firstAvailableAppointmentInPerson?.date &&
          firstAvailableAppointmentInPerson?.date.length > 0
        ) {
          const inPersonApptDatetime = firstAvailableAppointmentInPerson?.date

          if (isValid(new Date(inPersonApptDatetime))) {
            const inPersonApptDate = dateFirstAvailable(inPersonApptDatetime)
            setDateInPersonDisplay(`${inPersonApptDate} (in person)`)
          } else {
            console.error('Invalid date - in person appointment')
            setDateInPersonDisplay('')
          }
        } else {
          setDateInPersonDisplay('')
        }
      } catch (error) {
        // Handle errors if any
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (dateVirtualDisplay === null && dateInPersonDisplay === null) {
    return (
      <div className="flex flex-col gap-4 min-w-[215px] max-w-[310px] flex-grow">
        <Skeleton variant="rounded" height={16} width={120} />
        <Skeleton variant="rounded" height={16} width={148} />
        <Skeleton variant="rounded" height={16} width={200} />
      </div>
    )
  }

  return (
    (dateVirtualDisplay || dateInPersonDisplay) && (
      <div className={clsx('flex flex-col gap-y-1', align === 'center' && 'justify-center')}>
        <Typography variant={heading ? 'body-semibold' : 'small-body-semibold'} align={align}>
          First available
        </Typography>

        {/* Virtual */}
        {dateVirtualDisplay && (
          <Typography variant="small-body" align={align} data-testid="first-available-virtual-div">
            <Icon className="w-3.5 pr-1.5" icon="video" />
            {dateVirtualDisplay}
          </Typography>
        )}

        {/* In Person */}
        {dateInPersonDisplay && (
          <Typography variant="small-body" align={align} data-testid="first-available-person-div">
            <Icon className="w-3.5 pr-1.5" icon="buildings" />
            {dateInPersonDisplay}
          </Typography>
        )}
      </div>
    )
  )
}

export default FirstAvailable
