import type { TypeProviderPublic } from '@lib/provider-types'
import { Link, Typography } from '@shc/ui'
import Ratings from '@components/ratings'

export interface ProviderRatingsProps {
  review_rating: TypeProviderPublic['review_rating']
  review_count: TypeProviderPublic['review_count']
  link?: string
}

const ProviderRatings = ({ review_count, review_rating, link }: ProviderRatingsProps) => {
  if (review_count && review_count > 29) {
    return (
      <div className="flex flex-row items-center">
        {review_rating && <Ratings value={review_rating} />}
        {link ? (
          <Link className="pl-4" noUnderline href={link} aria-label="Provider Ratings">
            {review_count} ratings
          </Link>
        ) : (
          <Typography className="pl-4">{review_count} ratings</Typography>
        )}
      </div>
    )
  }

  return (
    <Link noUnderline href="/doctors#doctor-ratings-reviews-determined">
      No ratings available
    </Link>
  )
}

export default ProviderRatings
