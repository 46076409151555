'use client'

import { setSchedulingType } from '@lib/utilities/provider-utilities'
import type { TypeProviderPublic } from '@lib/provider-types'
import type { ContentfulImagesAPI, ImageSizes } from '@components/contentful-image'
import ContentfulImage from '@components/contentful-image'
import clsx from 'clsx'
import { aspectRatioMap } from '@lib/token-maps'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import FirstAvailable from '@app/[locale]/(main)/doctors/_components/first-available'
import { Icon, Typography } from '@shc/ui'
import Ratings from '@components/ratings'

export type ProviderCareCardProps = {
  careProvider: TypeProviderPublic
  className: string
}

const extras = generateBaseMediaQWidths(4)
const focalPoint = 'Face'
let aspectRatioVal = aspectRatioMap['1:1']
const imageSizes: ImageSizes[] | undefined = extras?.map((value) => {
  return {
    mediaQuery: value.mediaQuery,
    params: {
      w: value.w,
      h: Math.ceil(value.w / aspectRatioVal),
      fit: 'fill',
      f: focalPoint?.toLowerCase() as Lowercase<NonNullable<ContentfulImagesAPI['f']>>,
    },
  }
})

const ProviderCareCard = ({ careProvider, className }: ProviderCareCardProps) => {
  // set photo variable to either url from API or the photo placeholder
  const providerPhotoUrl =
    careProvider.photo_url && careProvider.photo_url.length > 0
      ? careProvider.photo_url?.replace(/^https?:/i, '')
      : '//images.ctfassets.net/pxcfulgsd9e2/4vtfSBGmp3LQhKtvjw2JIC/d0f2094db56d33c832190f5e281156e5/Placeholder_image.png'

  const schedulingType = setSchedulingType(careProvider)

  return (
    <div className={(clsx('pt-14 h-full'), className)}>
      <div className="flex flex-col h-full items-center bg-gray-50 drop-shadow-md rounded px-4 pb-8 gap-y-6 group hover:bg-gray-75 hover:drop-shadow-lg duration-200">
        <div className="flex flex-col gap-y-1">
          <div className="mx-auto -mt-[50px] overflow-hidden object-cover w-[100px] h-[100px] border-[6px] border-gray-50 group-hover:border-gray-75 rounded-full duration-200">
            <ContentfulImage
              src={providerPhotoUrl}
              imageSizes={imageSizes}
              alt="Provider Image"
              className="group-hover:scale-[1.03] ease-in-out duration-300"
            />
          </div>
          <div className="flex text-center">
            <Typography variant="body-semibold" className="text-primary">
              {careProvider.name_formatted_with_degrees}
            </Typography>
          </div>
          <div className="flex justify-center">
            {careProvider.review_count &&
            careProvider.review_count > 29 &&
            careProvider.review_rating ? (
              <Ratings value={careProvider.review_rating} />
            ) : (
              <div className="font-semibold text-sm">&nbsp;</div>
            )}
          </div>
          {careProvider.places && careProvider.places?.length > 0 ? (
            <Typography
              className="flex justify-center items-center"
              variant="small-body"
              align="center">
              <Icon className="text-primary pr-2" icon="location-dot" />
              {careProvider.places[0].city}
            </Typography>
          ) : (
            <div className="font-semibold text-sm">&nbsp;</div>
          )}
        </div>
        {schedulingType === 'Online' ? (
          <FirstAvailable provider={careProvider} align="center" heading />
        ) : (
          <div className="flex flex-col items-center gap-y-1">
            <Typography variant="small-body-semibold" align="center">
              Appointments available
            </Typography>
            <Typography variant="small-body" align="center">
              {careProvider.is_providing_virtual_visits
                ? 'In-person and virtual visits'
                : 'In-person'}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProviderCareCard
