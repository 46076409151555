'use client'

import type { TypeProviderPublic } from '@lib/provider-types'
import ProviderCareCard from '@app/[locale]/(main)/doctors/_components/provider-care-provider-card'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'
import { useContext, useState } from 'react'
import { Button, Icon, Typography, Link } from '@shc/ui'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import {
  BlockContext,
  ItemContext,
  PageContext,
  PlaceContext,
  ProviderContext,
} from '@lib/analytics'
import { AnalyticsLink } from '@components/analytics'

export type ProviderCarePartnersProps = {
  care_partners: TypeProviderPublic['care_partners']
}

const ProviderCarePartners = (props: ProviderCarePartnersProps) => {
  const { care_partners } = props
  const { trackSnowplow } = useAnalytics()
  const [prevEl, setPrevEl] = useState<HTMLButtonElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLButtonElement | null>(null)
  const [disable, setDisable] = useState({ prev: true, next: false })

  // Environment contexts
  const pageContext = useContext(PageContext)
  const placeContext = useContext(PlaceContext)
  const providerContext = useContext(ProviderContext)
  const blockContext = useContext(BlockContext)
  const itemContext = useContext(ItemContext)
  const environmentContexts: SPContext[] = [
    pageContext,
    placeContext,
    providerContext,
    blockContext,
    itemContext,
  ].filter((context) => context !== null)

  if (!care_partners) return null

  return (
    <>
      <Typography variant="h2" className="pb-8">
        Care partners
      </Typography>
      <Typography className="pb-7">
        You are welcome to book appointments with your doctor&apos;s care team.
      </Typography>
      {care_partners.length > 3 ? (
        <div className="flex flex-row gap-x-3">
          {care_partners.length !== 1 && (
            <div className="hidden lg:flex flex-row items-center justify-center">
              <Button
                color="transparent"
                size="sm"
                shape="circle"
                width="auto"
                aria-label="Previous Slide"
                ref={(node) => setPrevEl(node)}
                className="swiperPrev text-primary-700"
                disabled={disable.prev}>
                <Icon className="h-6" icon="chevron-left" />
              </Button>
            </div>
          )}
          <Swiper
            onSlideChangeTransitionStart={(swiper) => {
              setDisable({ prev: swiper.isBeginning, next: swiper.isEnd })
              trackSnowplow({
                event: { name: 'component_scroll', data: {} },
                contexts: [
                  {
                    name: 'component',
                    data: {
                      component_text: 'ProviderCarePartners',
                    },
                  },
                  ...environmentContexts,
                ],
              })
            }}
            watchOverflow={false}
            a11y={{
              enabled: true,
              firstSlideMessage: 'This is the first slide',
              lastSlideMessage: 'This is the last slide',
            }}
            keyboard={{
              enabled: true,
            }}
            modules={[Navigation, A11y]}
            navigation={{
              prevEl,
              nextEl,
            }}
            spaceBetween={20}
            breakpoints={{
              320: {
                slidesPerView: 1.5,
              },
              639: {
                slidesPerView: 2.3,
              },
              640: {
                slidesPerView: 2.3,
              },
              768: {
                slidesPerView: 3.2,
              },
              1024: {
                slidesPerView: care_partners.length > 2 ? 3 : care_partners.length,
              },
              1280: {
                slidesPerView: care_partners.length > 2 ? 3 : care_partners.length,
              },
            }}>
            {care_partners.map((carePartner) => {
              return (
                <SwiperSlide
                  key={carePartner.id}
                  className="pb-1 pt-14 w-full"
                  data-testid="provider-slides">
                  <a href={`/doctors/${carePartner.slug}`}>
                    <ProviderCareCard careProvider={carePartner} className="h-full w-full" />
                  </a>
                </SwiperSlide>
              )
            })}
          </Swiper>
          {care_partners.length !== 1 && (
            <div className="hidden lg:flex flex-row items-center justify-center">
              <Button
                color="transparent"
                size="sm"
                shape="circle"
                width="auto"
                aria-label="Next Slide"
                ref={(node) => setNextEl(node)}
                className="swiperNext text-primary-700"
                disabled={disable.next}>
                <Icon className="h-6" icon="chevron-right" />
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-row gap-x-5 overflow-x-auto">
          {care_partners.length < 4 &&
            care_partners.map((carePartner) => (
              <AnalyticsLink
                as={Link}
                key={carePartner.id}
                noUnderline
                href={`/doctors/${carePartner.slug}`}
                className="text-inherit pb-3 pt-14"
                snowplow={{
                  event: {
                    name: 'component_click',
                    data: {},
                  },
                  contexts: [
                    {
                      name: 'component',
                      data: {
                        component_text: carePartner.name_formatted_with_degrees ?? '',
                        component_url: `/doctors/${carePartner.slug}`,
                      },
                    },
                    {
                      name: 'content',
                      data: {
                        content_type: 'fadPromoBlock',
                        content_internal_name: 'Care Partners block',
                        content_level: 'block',
                      },
                    },
                  ],
                }}>
                <ProviderCareCard careProvider={carePartner} className="h-full w-[244px]" />
              </AnalyticsLink>
            ))}
        </div>
      )}
    </>
  )
}

export default ProviderCarePartners
