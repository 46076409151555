'use client'

import { useEffect, useState } from 'react'
import type { TypeProviderPublic } from '@lib/provider-types'
import throttle from 'lodash/fp/throttle'
import ProviderAppointmentButton from '@app/[locale]/(main)/doctors/_components/provider-appointment-button'
import { NavBar, NavItem } from '@shc/ui'
import type { MenuItemData } from '@lib/types'
import useAnalytics from '@hooks/use-analytics'

export type ProviderNavBarProps = {
  provider: TypeProviderPublic
  menuItems: MenuItemData[]
}

const ProviderNavBar = ({ provider, menuItems }: ProviderNavBarProps) => {
  const { trackSnowplow } = useAnalytics()
  const throttleMs = 100
  const sectionElementBuffer = 75
  const [activeSection, setActiveSection] = useState<string | null>(null)
  const [showButton, setShowButton] = useState(false)

  const sectionElementIds = menuItems.map((link) => {
    const href = link.route
    const sectionIndex = href.indexOf('#')
    if (sectionIndex != -1) {
      return href.substring(sectionIndex + 1)
    }
    return null
  })

  // For hydration
  useEffect(() => {
    setActiveSection(typeof window !== 'undefined' ? window.location.hash.slice(1) : null)
  }, [])

  useEffect(() => {
    const handleActiveSection = () => {
      for (const sectionElementId of sectionElementIds) {
        if (sectionElementId) {
          const section = document.getElementById(sectionElementId)
          const scrollOffSet = window.scrollOffSet + sectionElementBuffer

          if (section && section.getBoundingClientRect().top <= scrollOffSet) {
            setActiveSection(sectionElementId)
          }
        }
      }
    }

    const handleButtonVisibility = () => {
      const providerCardContainer = document.getElementById('provider-container')
      if (!providerCardContainer) return

      if (window.scrollY > providerCardContainer.offsetHeight + providerCardContainer.offsetTop) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }

    const handleScroll = throttle(throttleMs, () => {
      handleActiveSection()
      handleButtonVisibility()
    })

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <NavBar
      id="sub-nav"
      alignment="start"
      sticky
      // I (Hassen) had a convo with Grant which determined this is possibly dangerous since the code is assuming the internal structure would never change
      // we'll leave this in for now
      className="last:[&>ul>li]:ml-auto"
    >
      {menuItems.map(({ name, route, isInternal }) => {
        const active = activeSection === route.split('#')[1]
        const handleOnClick = () => {
          trackSnowplow({
            event: {
              name: 'navigation_click',
              data: {
                navigation_tree: name,
                navigation_level: 1,
                navigation_subject: name,
                navigation_url: route,
              },
            },
            contexts: [{ name: 'section', data: { section_name: 'subnav' } }],
          })
        }

        return (
          <NavItem
            key={name}
            href={route}
            target={isInternal ? undefined : '_blank'}
            rel={isInternal ? undefined : 'noopener noreferrer'}
            active={active}
            onClick={handleOnClick}>
            {name}
          </NavItem>
        )
      })}
      {showButton && (
        <ProviderAppointmentButton
          id="appointment-button-nav"
          provider={provider}
          buttonType="nav"
        />
      )}
    </NavBar>
  )
}

export default ProviderNavBar
