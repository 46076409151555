import useAnalytics from '@hooks/use-analytics'
import type { TypeProviderPublic, TypeInsurancePlan } from '@lib/provider-types'
import { Link, Typography, Modal, ExpandableContent, Collapsible } from '@shc/ui'
import { entries, flow, groupBy, map, sortBy } from 'lodash/fp'
import { Fragment, type Dispatch, type SetStateAction } from 'react'

type TypeGroupedInsurancePlans = {
  insurer_name: NonNullable<TypeInsurancePlan['insurer_name']>
  insurance_plans: TypeInsurancePlan[]
}

type ProviderInsuranceModalProps = {
  provider: TypeProviderPublic
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const ProviderInsuranceModal = ({
  provider,
  isModalOpen,
  setIsModalOpen,
}: ProviderInsuranceModalProps) => {
  const groupedInsurancePlans: TypeGroupedInsurancePlans[] = flow(
    groupBy('insurer_name'),
    entries,
    map(
      ([insurer_name, insurance_plans]: [
        TypeInsurancePlan['insurer_name'],
        TypeInsurancePlan[],
      ]) => ({
        insurer_name,
        insurance_plans,
      })
    ),
    sortBy('insurer_name')
  )(provider.insurance_plans)
  const { trackSnowplow } = useAnalytics()

  const handleOpenChange = (state: boolean, insurer_name: string) => {
    trackSnowplow({
      event: { name: state ? 'component_expand' : 'component_collapse', data: {} },
      contexts: [
        {
          name: 'component',
          data: {
            component_text: insurer_name,
          },
        },
      ],
    })
  }

  return (
    <Modal
      width="md"
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
        trackSnowplow({
          event: { name: 'modal_close', data: {} },
          contexts: [{ name: 'modal', data: { modal_name: 'InsuranceModal' } }],
        })
      }}>
      <div className="flex flex-col" data-testid="insurance-modal">
        <Typography variant="h2" align="center" className="px-7 pb-8">
          Insurance plans accepted
        </Typography>
        <Typography className="pb-8">
          Sharp accepts most health insurance. Not all plans are listed. Please contact your
          insurance company to verify your insurance type and plan.
        </Typography>
        <Typography variant="body-semibold" className="pb-4">
          In-network insurance plans
        </Typography>
        <ExpandableContent>
          {groupedInsurancePlans.map(({ insurer_name, insurance_plans }) => (
            <Collapsible
              key={insurer_name}
              heading={insurer_name}
              description={
                <div>
                  {insurance_plans.map(({ id, insurance_plan_type_name }, index) => (
                    <Fragment key={id}>
                      <Typography>{insurance_plan_type_name}</Typography>
                      {index === insurance_plans.length - 1 &&
                        insurance_plans[index].insurer_url && (
                          <Typography className="pt-4">
                            <Link
                              noUnderline
                              href={insurance_plans[index].insurer_url}
                              aria-label="Insurance Website"
                              target="_blank">
                              View insurance website
                            </Link>
                          </Typography>
                        )}
                    </Fragment>
                  ))}
                </div>
              }
              onOpenChange={(open: boolean) => handleOpenChange(open, insurer_name)}
            />
          ))}
        </ExpandableContent>
      </div>
    </Modal>
  )
}

export default ProviderInsuranceModal
